<template>
  <CCard class="zq--common-card">
    <!--  HEADER  -->
    <CCardHeader class="zq--common-card--header">
      <CIcon :name="headerIcon" class="mr-2"></CIcon>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <!--  BODY  -->
    <CCardBody class="zq--common-card--body">
      {{ content }}
    </CCardBody>
    <!--  FOOTER  -->
    <CCardFooter class="zq--common-card--footer">
      <router-link :to="to">
        <div class="zq--common-card-icon-rounded">
          <CIcon
              :name="footerIcon"
              customClasses="zq--common-card-svg"
              height="12">
          </CIcon>
        </div>

      </router-link>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  props: {
    headerIcon: String,
    footerIcon: String,
    to: [Object, String],
    title: String,
    content: String
  }
}
</script>

<style lang="scss">
.zq--common-card {
  flex: 1;
  flex-basis: 260px;
  margin: 8px 16px 1px 1px;
  padding: 0 0 16px;

  .zq--common-card--header {
    border-bottom: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #282f37;
  }

  .zq--common-card--body {
    overflow: auto;
    opacity: 0.7;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #282f37;
  }

  .zq--common-card--footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-top: none;
    padding-bottom: 0;
  }


  .zq--common-card-icon-rounded {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #282f37;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    right: 1.2rem;
    background: #FFFFFF;

    &:hover {
      background-color: #282f37;
      opacity: 0.9;

      .zq--common-card-svg {
        color: #FFFFFF;
      }
    }

    &:active, &:focus {
      opacity: 1;
    }

    .zq--common-card-svg {
      color: #282f37;
    }
  }
}

.card.zq--common-card {
  max-height: 370px;
}
</style>